
@font-face {
    font-family: "Lato-Regular";
    src: url(../../fonts/Lato/Lato-Regular.ttf);
}

@font-face {
    font-family: "Inconsolata-Regular";
    src: url(../../fonts/Inconsolata/Inconsolata-Regular.ttf);
}
@font-face {
    font-family: "Inconsolata-bold";
    src: url(../../fonts/Inconsolata/Inconsolata-Bold.ttf);
}
@font-face {
    font-family: "Raleway-Regular";
    src: url(../../fonts/Raleway/Raleway-Regular.ttf);
}
@font-face {
    font-family: "Raleway-bold";
    src: url(../../fonts/Raleway/Raleway-Bold.ttf);
}
body,html{
    margin: 0;
    width: 100%;
    overflow-x: hidden;
}

.header-logo-unitro{
    display: flex;
    align-items: center;
    justify-content: center;

}

.header-logo-unitro img{
    width: 20%;
    margin: 1rem;
    max-width: 217px;
}
@media screen and (max-width: 500px) {
    .header-logo-unitro img{
        width: 40%;
        margin: 1rem;
    }
}
.lot-details-box{
    display: flex;
    justify-content: center;
    
}
.lot-details{
    box-shadow: 0px 8px 12px rgba(0, 20, 89, 0.15);
    width: 85%;
}
.lot-details-header{
    border-radius: 5px 5px 0 0;
    background-color: #2B2B2B;
    float: none;
    margin: 0 auto;
    color: #fff;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Lato-Regular" ;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2px;

}
.beekeeper-details{
    margin: auto;    
    
}
.beekeeper-details-title{
    text-align: left;
    font-family: "Inconsolata-Regular";
    font-weight: 650;
    letter-spacing: 0.7px;
    color:#999999;
    margin: 1rem 0 0.5rem 0;
    font-size: 12px;
}
.beekeeper-details-name{
    text-align: left;
    font-family: "Raleway-bold";
    font-size: 15px;
    margin-bottom: 1rem;
}
.separator{
    border-bottom: 2px solid rgba(0,0,0, 0.1);
    border-radius: 30px;
}
.origin-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icons-box{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
}
.icons-box-honey{
    display: flex;
    align-items: center;
    justify-content: center;
}
.caroussel-bannerX {
    display: flex;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}
  @media screen and (max-width: 768px) {
    .caroussel-bannerX {
      overflow-x: auto;
    }
  }
.photos{
    display: flex;
    align-items: center;
    
    justify-content: center;
}

.photos img{
    height: 120px;
    border-radius: 10px;
    margin: 10px;
}
.photos-label{
    padding:30px 0 0 30px;
    display: flex;
    align-items: center;
    text-align: left;
    font-family: "Inconsolata-bold";
    font-size: 14px;
}
.argentina-icon-box{
    display: flex;
    justify-content: flex-start;
    
    
}

.caroussel-photo{
    padding: 0.5rem 0;   
}
.caroussel-photo img{
        
    border: 3px solid white;
    filter: drop-shadow(0px 8px 12px rgba(0, 20, 89, 0.15));
    height: 250px;
    cursor: pointer;
}

.caroussel-photo video{
    border: 3px solid white;
    filter: drop-shadow(0px 8px 12px rgba(0, 20, 89, 0.15));
    height: 250px;
    cursor: pointer;
}
@media screen and (max-width:550px) {
    .caroussel-photo img{
        height: 200px;
    }
}
.importer-view-box{
    margin: 2rem 0;
}
.importer-view-button{
    background-color: #8A4D96;
    padding: 10px 60px;
    border-radius: 5px;
    font-family: "Raleway-bold";
    font-weight: 600;
    color: #fff;
    font-size: 13px;
}
.contact-us{
    font-family: "Raleway-bold";
    font-size: 13px;
    margin: 1rem;
}
.contact-us a{
    text-decoration: none;
    color:#8A4D96;

}