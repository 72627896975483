.page-title-importer{
    top: 0px;
    padding: 10px 0;
    font-weight: 600;
    /* margin: 20px 0; */
    
    display: flex;
    align-items: center;
}
.importer-page{
    padding-top: 2rem;
    background: linear-gradient(180deg, #F1F1F1 0%, rgba(255, 255, 255, 0) 100%);
}
.page-title-importer-col{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    height: 40px;
}
.page-title-importer-text{
    width: 100%;
}

.info-span{
    padding: 7px 0px;

}
.importer-title{
    font-family: "Roboto-regular";
    padding: 1rem 0;
}
.docs-label{
    text-align: center;
}
.verify-box{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 4px 9px 12px -7px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 9px 12px -7px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 9px 12px -7px rgba(0,0,0,0.75);
    width: 85%;
    margin: 1rem auto;
    padding: 10px;
    font-family: "Roboto-regular";
    text-align: left;
}
.thumbnails-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.documents-wrapper{
    width: 80%;
    margin: 1rem auto;
}
.documents-wrapper-box{
    margin-top: 1rem;
    border-radius: 5px;
    box-shadow: -1px 3px 11px -1px rgba(0,0,0,0.3);
    -webkit-box-shadow: -1px 3px 11px -1px rgba(0,0,0,0.3);
    -moz-box-shadow: -1px 3px 11px -1px rgba(0,0,0,0.3);
}
.documents-wrapper-box-title{
    text-align: left;
    font-family: "Raleway-bold";
    background-color: #8A4D96;
    padding:13px 10px;
    color: #fff;
    font-size: 14px;
    border-radius: 5px 5px 0 0 ;
}
.laboratory-analisis-box{
    display: flex;
    flex-direction: column;
}
.category-wrapper{
    width: 50%;
}
.category-title{
    color: rgb(126, 126, 126);
    text-align: left;
    margin: 15px 0 0 0;
}
.document-item{
    height: 50px;
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.doc-name{
   text-align: left;
}
.document-item-box a {
    text-decoration: none;
    color: black;
    font-family: Raleway-bold;
    font-size: 14px;
}
.separator{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}
