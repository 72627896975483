.pdf-container{
    margin: 2rem auto;
    display: flex;
    justify-content: space-around;
    width: 95%;
    
}
.pdf-box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    background-color: #9257A2;
    box-shadow: 0px 8px 12px rgba(0, 20, 89, 0.15);
    border-radius: 5px;
    cursor:pointer;

}
.ots-box{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 180px;
    background-color: #9257A2;
    box-shadow: 0px 8px 12px rgba(0, 20, 89, 0.15);
    border-radius: 5px;
    cursor:pointer;
}
.disclaimer{
    text-align: center;
    font-family: "Raleway-Regular";
    font-weight: 700;
    letter-spacing: 0.2px;
    color:red;
    margin: 1rem 0 0.5rem 0;
    font-size: 9px;
}
.verify-documents-title{
    font-family: Raleway-bold;
    font-weight: 900;
    font-size: 14px;
    margin: 1rem 0;
}
.verify-section-box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.line{
    border: 0.7px solid rgba(0,0, 0, 0.1);
    width: 100px;
    height: 0;
    margin: auto 5px;
}
.download-step{
    font-size: 10px;
    text-align: center;
    font-family: Raleway-Regular;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.verify-section-link{
    margin: 1rem 0;
}
.verify-button-box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.verify-button-box a {
    text-decoration: none;
}
.verify-button{
    background-color: #8A4D96;
    padding: 10px 2rem;
    border-radius: 5px;
    color: #fff;
    font-family: Raleway-Regular;
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 13px;
}